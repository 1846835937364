import React, { useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../../global.css';
import './style.css';

import api from '../../services/api';

import Banner from '../../assets/img/banner-editora.jpg';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

export default function Biblioteca(props){

    const token = localStorage.getItem('token');
    const [livros, setLivros] = useState([]);
    const [editora, setEditora] = useState([]);
    const [user,setUser] = useState([]);

    const history = useHistory();

    useEffect(()=>{
        api.get(`api/editora/`+props.match.params.editoraId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response=>{
            if(response.data.status){
                alert('Sessão expirado');
                history.push('/');
            }
            setEditora(response.data);
            //console.log(editora)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.match.params.editoraId, token]);

    useEffect(()=>{
        api.get(`api/livros/`+props.match.params.editoraId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response=>{
            if(response.data.status){
                history.push('/');
            }
            setLivros(response.data);
            //console.log(livros)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.match.params.editoraId, token]);

    useEffect(() =>{
      api.get("api/user/assinatura",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        // if (response.data.status) {
        //   history.push("/");
        // }
        // setPodcasts(response.data);
        console.log(response)
        setUser(response.data)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <div className="container">
        <Header />
        <main className="main-container">
            <div class="main-section">
                <div className="banner">
                    <img src={Banner} alt={"Banner"}/>
                </div>
                <div className="content-area">
                    <div className="editora-description">
                        <img src={editora.logo} alt={"logoEditora"}/>
                        <div className="editora-text-block">
                            <h4 className="editora-title">{editora.name}</h4>
                            <div className="editora-description-text">
                                {editora.description}
                            </div>
                            <div className="editora-description-span">Endereço: </div>
                            <div className="editora-description-text">{editora.address}</div>
                            <div className="editora-description-span">Telefone: </div>
                            <div className="editora-description-text">{editora.phone}</div>
                            <div className="editora-description-span">Site: </div>
                            <div className="editora-description-text">{editora.website}</div>
                        </div>
                    </div>
                    <div className="biblioteca">
                        <Link to="/dashboard"><button className="button-dashboard" style={{display:'none'}}>Voltar</button></Link>
                        <h3>BIBLIOTECA</h3>
                        <div className="block-livros">
                          {user.is_costumer===1 ?
                            livros.map(livro =>(
                              <Link to={{pathname: `/audiolivro/${livro.id}`}}>
                                <img src={livro.thumbnail} className="books" alt="img-livro"/>
                              </Link>
                            ))
                          :
                            editora.desctacado_imagem_1 ? (
                              <Link
                                to={{
                                  pathname: `/audiolivro/${editora.id_destacados_1}`,
                                }}
                              >
                                <img
                                  className="books"
                                  src={editora.desctacado_imagem_1}
                                  alt="img-livro"
                                />
                              </Link>
                            ):(
                              <div className="assinatura">
                                <p>Seu tipo de assinatura não permite ver esta categoria</p>
                                <a className="button-contato" href="https://walink.co/e879e6" target="blank">
                                    Assine Aqui
                                </a>
                              </div>
                            )
                          }
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
    );
}
