import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import  isAuthenticated  from './auth';

import Dashboard from './pages/Dashboard';
import Biblioteca from './pages/Biblioteca';
import Audiolivro from './pages/Audiolivro';
import Podcast from './pages/Podcast';
import Audiopodcast from './pages/Audiopodcast';
import Login from './pages/Login';
import Cadastro from './pages/Cadastro';

const PrivateRoute = ({ component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props}/>
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location}}} />
            )
        }
    />
)

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login}></Route>
                <Route path="/cadastro" component={Cadastro} ></Route>
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/biblioteca/:editoraId" component={Biblioteca} />
                <PrivateRoute path="/audiolivro/:livroId" component={Audiolivro}/>
                <PrivateRoute path="/podcast" exact component={Podcast}/>
                <PrivateRoute path="/podcast/:podcastId" component={Audiopodcast}/>
            </Switch>
        </BrowserRouter>
    );
}