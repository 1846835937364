import React from 'react';
import './style.css';

const Footer = () => (
    <footer>
        <div className="footer">
            <span>OnBook ® Todos os Direitos Reservados</span>
            <span>Desenvolvido por <a href="https://galpha.co" target="_blank" rel="noopener noreferrer">Grupo Alpha</a></span>
        </div>
    </footer>
);

export default Footer;