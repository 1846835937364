import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../global.css";
import "./style.css";

import api from "../../services/api";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import Banner from "../../assets/img/banner-dashboard.jpg";

export default function Login() {
  const token = localStorage.getItem("token");
  const [editoras, setEditoras] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [user,setUser] = useState([]);

  const history = useHistory();

  useEffect(() => {
    api.get("api/editoras", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          alert("Sessão expirado");
          history.push("/");
        }
        setEditoras(response.data);
        //console.log(editoras)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api.get("api/podcasts", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.data.status) {
        history.push("/");
      }
      setPodcasts(response.data);
      //console.log(podcasts)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() =>{
    api.get("api/user/assinatura",{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      // if (response.data.status) {
      //   history.push("/");
      // }
      // setPodcasts(response.data);
      console.log(response)
      setUser(response.data)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <Header />
      <main className="main-container">
        <div className="main-section">
          <div className="banner">
            <img src={Banner} alt="Banner" />
            <p>
            Um recurso digital de prestação de serviços de leitura e streaming de áudio, para agregar dinamismo e produtividade no aprendizado e no aperfeiçoamento pessoal e intelectual. Aqui você está a um clique do conhecimento para alavancar sua carreira. OnBook - Tudo no Seu Tempo.
            </p>
          </div>
          <div className="content-area">
            <div className="editors-list">
              <h3>AUDIOLIVROS</h3>
                {editoras.map((editora) => (
                  <div className="bloque-editor" key={editora.id}>
                    <Link to={{ pathname: `/biblioteca/${editora.id}` }}>
                      <img
                        className="logo-dashboard"
                        src={editora.logo}
                        alt="logoEditora"
                      />
                    </Link>
                    <div className="texto-editor">
                      <div className="bloque-1-text">
                        <h3>{editora.name}</h3>
                        <h5>{editora.number_titles} Títulos</h5>
                      </div>
                      <Link to={{ pathname: `/biblioteca/${editora.id}` }}>
                        <h4 className="link-text">VER TODOS</h4>
                      </Link>
                    </div>
                    <div className="livros-editor">
                      {editora.desctacado_imagem_1 ? (
                        <Link
                          to={{
                            pathname: `/audiolivro/${editora.id_destacados_1}`,
                          }}
                        >
                          <img
                            className="img-destacado"
                            src={editora.desctacado_imagem_1}
                            alt="img"
                          />
                        </Link>
                      ) : (
                        <div className="img-destacado-vazio"></div>
                      )}
                      {editora.desctacado_imagem_2 && user.is_costumer ===1 ? (
                        <Link
                          to={{
                            pathname: `/audiolivro/${editora.id_destacados_2}`,
                          }}
                        >
                          <img
                            className="img-destacado"
                            src={editora.desctacado_imagem_2}
                            alt="img"
                          />
                        </Link>
                      ) : (
                        <div className="img-destacado-vazio"></div>
                      )}
                      {editora.desctacado_imagem_3 && user.is_costumer ===1 ? (
                        <Link
                          to={{
                            pathname: `/audiolivro/${editora.id_destacados_3}`,
                          }}
                        >
                          <img
                            className="img-destacado"
                            src={editora.desctacado_imagem_3}
                            alt="img"
                          />
                        </Link>
                      ) : (
                        <div className="img-destacado-vazio"></div>
                      )}
                      {editora.desctacado_imagem_4 && user.is_costumer ===1 ? (
                        <Link
                          to={{
                            pathname: `/audiolivro/${editora.id_destacados_4}`,
                          }}
                        >
                          <img
                            className="img-destacado"
                            src={editora.desctacado_imagem_4}
                            alt="img"
                          />
                        </Link>
                      ) : (
                        <div className="img-destacado-vazio"></div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className="podcast-list">
              <h3>PODCAST</h3>
              {user.is_costumer===1 ?
                podcasts.map((podcast) => (
                  <>
                    <div className="bloque-podcast">
                      <img src={podcast.image} alt="img" className="logo-podcast" />
                      <div className="texto-podcast">
                        <h3>{podcast.name}</h3>
                        <h5> </h5>
                        <Link to={{ pathname: `/podcast/${podcast.id}` }}>
                          <h4>{podcast.author}</h4>
                        </Link>
                      </div>
                    </div>
                  </>
                ))
              :
                <div className="bloque-podcast">
                  <div className="assinatura">
                    <p className="mb-7">Seu tipo de assinatura não permite ver esta categoria</p>
                    <a className="button-contato" href="https://walink.co/e879e6" target="blank">
                        Assine Aqui
                    </a>
                  </div>
                </div>
              }
              {user.is_costumer===1 ?
                <Link to={{ pathname: `/podcast` }}>
                  <h3>VER TODOS</h3>
                </Link>
              :
                <></>
              }
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
