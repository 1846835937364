import api from '../src/services/api';

export default function isAuthenticated ()
{
  // const token = localStorage.getItem('token');
  // const {key} = api.get('api/authorization', {
  //   headers: { 
  //     Authorization: `Bearer ${token}` 
  //   }
  // }).then(res => (res.data.status));
  
  // console.log(key);

  // return key;

      const token = localStorage.getItem('token');
      //console.log(token);
      if(token) { //¿Hay token?
          try{
              const data = api.get('api/authorization',{
                headers: { 
                      Authorization: `Bearer ${token}` 
              }});
              const key = data; //Contiene 'true' o 'false' dependiendo de si es encontrado
              //console.log(key);
              return key;
          }catch(error){
              //console.log('Hay token pero es falso');
          }
      }else{
          //console.log('No hay token');
          localStorage.removeItem('token')
          return false;
      }

}