import React from 'react';

import './global.css';
import Routes from './routes';

const App = () => (
  <Routes/>
);

export default App;
