import React, { useEffect, useState} from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';

import '../../global.css'; 
import './style.css';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';

export default function Audiopodcast(props){

    const token = localStorage.getItem('token');
    const [audiospod, setAudiospod] = useState([]);
    const [podcast, setPodcast] = useState([]);

    const history = useHistory();

    useEffect(()=>{
        api.get(`api/podcasts/`+props.match.params.podcastId, {
            headers: { 
                Authorization: `Bearer ${token}` 
            }
        }).then(response=>{
            if(response.data.status){
                alert('Sessão expirado');
                history.push('/');
            }
            setPodcast(response.data);
            //console.log(podcast)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.match.params.podcastId, token]);
    
    useEffect(()=>{
        api.get(`api/podcasts/audios/`+props.match.params.podcastId, {
            headers: { 
                Authorization: `Bearer ${token}` 
            }
        }).then(response=>{
            if(response.data.status){
                history.push('/');
            }
            setAudiospod(response.data);
            //console.log(audiospod)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.match.params.podcastId, token]);

    return(
    <div className="container">
        <Header />
        <main className="main-container">
            <div class="main-section">
                {/* <div className="banner">
                    <img src={Banner} alt={"Banner"}/>
                </div> */}
                <div className="content-area">
                    <Link to="/podcast">
                        <button className="voltar" style={{display:'none'}}>Voltar</button>
                    </Link>
                    <div className="capa-livro">
                        <img className="img-livro" src={podcast.image} alt="Sonha"/>
                        <h3 className="title">Descrição</h3>
                        <p className="livro-descricao">{podcast.descricao}</p>       
                    </div>
                    <div className="block-player">
                        <h4>{podcast.author}</h4>
                        <h3>{podcast.name}</h3>  
                        <div className="players">
                            {audiospod.map(audiopod =>(
                                <div key={audiopod.id} className="player">
                                    <div class="podcast-title">
                                        <span>Podcast </span>
                                        <h4>{audiopod.title}</h4>
                                    </div>
                                    <ReactAudioPlayer
                                        src={audiopod.link}
                                        controls
                                        controlsList="nodownload"
                                        className="player-estilos"
                                    />
                                </div>
                            ))} 
                        </div>
                    </div>
                </div>
                {/* <div className="content-area">
                    <div className="biblioteca" id="biblioteca-full">
                        <h3>BIBLIOTECA</h3>
                        <div className="block-livros">
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                        </div>  
                    </div>
                </div> */}
            </div>
        </main>
        <Footer />
    </div>
    );
}
