import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../global.css";
import "./style.css";
import ClipLoader from "react-spinners/ClipLoader";
import logo from "../../assets/img/logo.png";

import api from "../../services/api";

export default function Login() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleRegister(e) {
    e.preventDefault();

    const data = {
      name,
      email,
      password,
    };

    try{
      const response = await api.post("api/user/store", data);
      console.log(response);
      if(response.data.errorInfo)
      {
        if(response.data.errorInfo[1]===1048){
          loading = setLoading(false);
          alert('O campo nome não pode ser vazio');
          return
        }
        if(response.data.errorInfo[1]===1062){
          loading = setLoading(false);
          alert('O campo email já está em uso');
          return
        }
      }
      localStorage.setItem('token', response.data.access_token);
      history.push('/dashboard');
    }catch(err){
      console.log(err);
      loading = setLoading(false);
      alert('Serviço indisponivel. Tente mais tarde.')
    }



    // alert(`Seu Email de Acesso: ${response.data.email}`);
    // history.push("/");
  }

  return (
    <div className="container-full">
      <div className="login-box">
        <div className="container-logo">
          <img src={logo} alt=""></img>
          <h3>O SOM DO CONHECIMENTO</h3>
          <br></br>
          <p className="info">
            Para mais informacões, entre em contato conosco através do email{" "}
            <a href="mailto:contato@leitorvirtual.com.br">
              contato@leitorvirtual.com.br
            </a>
            .
          </p>
        </div>
        <div className="container-form">
          <div className="botoes">
            <div className="login2">
              <Link to="/">LOGIN</Link>
            </div>
            <div className="cadastro2">
              <Link to="/cadastro">CADASTRO</Link>
            </div>
          </div>
          <form onSubmit={handleRegister} className="form" autoComplete="off">
            <label>USER</label>
            <div className="inputForm">
              <input
                placeholder="Usuario"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <label>EMAIL</label>
            <div className="inputForm">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <label>PASSWORD</label>
            <div className="inputForm">
              <input
                type="password"
                placeholder="Senha"
                value={password}
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <label>CODIGO</label>
            <div className="inputForm">
              <input
                type="text"
                placeholder="Codigo Resgate"
              />
            </div>
            <div className="spinner">
              <button onClick={() => setLoading(!loading)} className="button" type="submit">
                ENVIAR
              </button>
              <ClipLoader loading={loading} size={20} />
            </div>
          </form>
          <div className="esqueceu">
            <a href="mailto:contato@leitorvirtual.com.br">
              Esqueceu sua Senha?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
