import React from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';
import logo from '../../assets/img/logo.png';
import api from '../../services/api';


export default function Header(){

    const history = useHistory();
    const token = localStorage.getItem('token');
    //console.log(token);

    async function handleLogout(e){
        e.preventDefault(); 

        try{
            await api.post('api/auth/logout',{}, api.defaults.headers.common = {'Authorization': `Bearer ${token}`});
            localStorage.removeItem('token');
            history.push('/');
        }catch(err){
            //console.log(token);
        }
    }

    return(
    <header>
        <div className="header">
            <a href="/dashboard"><img src={logo} alt={"logo"}/></a>
            <nav>
                <ul className="menu">
                    <li><a href="/dashboard">DASHBOARD</a></li>
                    <li><a href="/podcast">PODCAST</a></li>
                    <li>
                        <form onSubmit={handleLogout}>
                            <button className="logout" type="submit">LOGOUT</button>
                        </form>
                    </li>
                </ul>
                <ul className="menu-responsive">
                    <li>
                        <form onSubmit={handleLogout}>
                            <button className="logout" type="submit">LOGOUT</button>
                        </form>
                    </li>
                </ul>
            </nav> 
            {/* <div className="profile">
            <a href="./usuario"><img src={profile} alt={"Profile"}/></a>
            </div> */}
        </div>
    </header>
    );
}

