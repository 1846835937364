import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";

import api from "../../services/api";

import "../../global.css";
import "./style.css";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

export default function Audiolivro(props) {
  const token = localStorage.getItem("token");
  const [audios, setAudios] = useState([]);
  const [livro, setLivro] = useState([]);
  const [editora, setEditora] = useState([]);
  const [playState, setPlayState] = useState(false);
  const [user, setUser] = useState([]);

  const playFunction = (event) => {
    console.log("Your event: ", event.target.id);
    api.get(`api/relatorio/audio/` + event.target.id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response);
    });
  };

  const history = useHistory();

  useEffect(() => {
    api.get(`api/livro/` + props.match.params.livroId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.data.status) {
        alert("Sessão expirado");
        history.push("/");
      }
      setLivro(response.data);
      //console.log(livro)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.match.params.livroId, token]);

  useEffect(() => {
    api.get(`api/audios/` + props.match.params.livroId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.data.status) {
        history.push("/");
      }
      setAudios(response.data);
      //console.log(audios,response)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.match.params.livroId, token]);

  useEffect(() => {
    api.get(`api/editora/${livro.id_editora}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.data.status) {
        history.push("/");
      }
      setEditora(response.data);
      // console.log(editora)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[livro.id_editora]);

  useEffect(() => {
    api.get("api/user/assinatura", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // if (response.data.status) {
      //   history.push("/");
      // }
      // setPodcasts(response.data);
      console.log(response);
      setUser(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div className="container">
      <Header />
      <main className="main-container">
        <div class="main-section">
          {/* <div className="banner">
                    <img src={Banner} alt={"Banner"}/>
                </div> */}
          <div className="content-area">
            <Link to={{ pathname: `/biblioteca/${livro.id_editora}` }}>
              <button className="voltar" style={{ display: "none" }}>
                Voltar
              </button>
            </Link>
            <div className="capa-livro">
              <img className="img-livro" src={livro.image} alt="Sonha" />
              <span>Autor: {livro.author}</span>
              <h3 className="title">Sinopse</h3>
              <p className="livro-descricao">{livro.sinopsis}</p>
            </div>
            <div className="block-player-audio">
              <div className="block-title-players">
                <div className="info">
                  <h4>{editora.name}</h4>
                  <h3>{livro.name}</h3>
                  <h4 id="player-h4">{livro.number_chapters} Capitulos</h4>
                </div>
                <Link to={{ pathname: `/biblioteca/${livro.id_editora}` }}>
                  <img src={editora.logo} alt="LogoEditora" />
                </Link>
              </div>
              <div className="players-box">
                {user.is_costumer ===1 ?
                  audios.map((audio) => (
                    <div key={audio.id} className="player">
                      <div className="titulo-audiopodcast">
                        Capitulo {audio.number_chapter} - {audio.title_chapter}
                      </div>
                      <ReactAudioPlayer
                        id={audio.id}
                        src={audio.link}
                        controls
                        controlsList="nodownload"
                        className="player-estilos"
                        autoPlay={playState}
                        onPlay={playFunction}
                      />
                    </div>
                  ))
                :
                  user.is_costumer ===0 && (editora.id_destacados_1 === parseInt(props.match.params.livroId)) ?
                    audios.map((audio) => (
                      <div key={audio.id} className="player">
                        <div className="titulo-audiopodcast">
                          Capitulo {audio.number_chapter} - {audio.title_chapter}
                        </div>
                        <ReactAudioPlayer
                          id={audio.id}
                          src={audio.link}
                          controls
                          controlsList="nodownload"
                          className="player-estilos"
                          autoPlay={playState}
                          onPlay={playFunction}
                        />
                      </div>
                    ))
                  :
                    <div className="assinatura">
                      <p>Seu tipo de assinatura não permite ver esta categoria</p>
                      <a className="button-contato" href="https://walink.co/e879e6" target="blank">
                          Assine Aqui
                      </a>
                    </div>
                }
              </div>
            </div>
          </div>
          {/* <div className="content-area">
                    <div className="biblioteca" id="biblioteca-full">
                        <h3>BIBLIOTECA</h3>
                        <div className="block-livros">
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                            <Link to="/audiolivro"><img src={seteVerdades} /></Link>
                        </div>
                    </div>
                </div> */}
        </div>
      </main>
      <Footer />
    </div>
  );
}
