import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../../global.css';
import './style.css';
import logo from '../../assets/img/logo.png';
import ClipLoader from "react-spinners/ClipLoader";

import api from '../../services/api';
import { useEffect } from 'react';

export default function Login(){

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    let [loading, setLoading] = useState(false);
    const[link,setLink] = useState('https://onbookonline.com/');

    const history = useHistory();

    useEffect(()=>{
        api.get('api/link'
        ).then(response=>{
            console.log(response)
            setLink(response.data.link);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    async function handleLogin(e){
        e.preventDefault();

        const data = {
            email,
            password,
        };

        try{
            const response = await api.post('api/auth/login', data);
            if(response.data.error === 'Usuario Suspendido'){
                loading = setLoading(false);
                alert('Usuario Suspendido, entre em contato com o Administrador')
                return;
            }
            localStorage.setItem('token', response.data.access_token);
            history.push('/dashboard');
        }catch(err){
            console.log(err.response);
            if(err.response && err.response.status===401){
                loading = setLoading(false);
                alert('Email ou Senha Inválidas, Tente novamente')
            }
            else{
                loading = setLoading(false);
                alert('Serviço indisponivel. Tente mais tarde.')
                return
            }
        }
    }

    return(
    <div className="container-full">
        <div className="login-box">
            <div className="container-logo">
                <img src={logo} alt=""></img>
                <br></br>
                <p className="info">Para mais informacões, entre em contato conosco através do
                    email <a href="mailto:contato@leitorvirtual.com.br">
                    contato@leitorvirtual.com.br</a>.
                </p>
                <div className="div-buttons">
                    <a className="button-contato" href={link} target="blank">
                        Assine Aqui
                    </a>
                    <a href="https://walink.co/e879e6" className="button-whats" target="blank">
                        Contato
                    </a>
                </div>
            </div>
            <div className="container-form">
                <div className="botoes">
                    <div className="login"><Link to="/">LOGIN</Link></div>
                    <div className="cadastro"><Link to="/cadastro">CADASTRO</Link></div>
                </div>
                <form onSubmit={handleLogin} className="form">
                    <label>EMAIL</label>
                    <div className="inputForm">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <label>PASSWORD</label>
                    <div className="inputForm">
                        <input
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="spinner">
                        <button onClick={() => setLoading(!loading)} className="button" type="submit">ENVIAR</button>
                        <ClipLoader loading={loading} size={20} />
                    </div>
                </form>
                <div className="esqueceu">
                    <a href="mailto:contato@leitorvirtual.com.br">Esqueceu sua Senha?</a>
                </div>
            </div>
        </div>
    </div>
    );
}
