import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../global.css";
import "./style.css";

import api from "../../services/api";

import Banner from "../../assets/img/banner-editora.jpg";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

export default function Biblioteca(props) {
  const token = localStorage.getItem("token");
  const [podcasts, setPodcasts] = useState([]);
  const [user, setUser] = useState([]);

  const history = useHistory();

  useEffect(() => {
    api
      .get(`api/podcasts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          alert("Sessão expirado");
          history.push("/");
        }
        setPodcasts(response.data);
        //console.log(podcasts)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.editoraId, token]);

  useEffect(() => {
    api
      .get("api/user/assinatura", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // if (response.data.status) {
        //   history.push("/");
        // }
        // setPodcasts(response.data);
        console.log(response);
        setUser(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <Header />
      <main className="main-container">
        <div class="main-section">
          <div className="banner">
            <img src={Banner} alt={"Banner"} />
          </div>
          <div className="content-area">
            {/* <div className="editora-description">
              <img src="" alt={"logoEditora"}/>
              <h4></h4>
              <p></p>
              <span>Endereço: </span><br></br>
              <span></span><br></br><br></br>
              <span>Telefone: </span><br></br>
              <span></span><br></br><br></br>
              <span>Site: </span><br></br>
              <span></span><br></br><br></br>
              </div> */
            }
            <div className="biblioteca-pod">
              <h3>PODCAST</h3>
              <div className="block-livros-pod">
                {user.is_costumer===1 ?
                  podcasts.map((podcast) => (
                    <div className="block-podcast-img">
                      <Link to={{ pathname: `/podcast/${podcast.id}` }}>
                        <img
                          src={podcast.thumbnail}
                          className="books-pod"
                          alt="img-livro"
                        />
                      </Link>
                      <div class="name-podcast">{podcast.name}</div>
                    </div>
                  ))
                :
                  <div className="assinatura">
                    <p>Seu tipo de assinatura não permite ver esta categoria</p>
                    <a className="button-contato" href="https://walink.co/e879e6" target="blank">
                        Assine Aqui
                    </a>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
